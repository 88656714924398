<template>
  <div>
    <b-card v-if="users.length !== 0">
      <b-card-header>
        <b-card-title>
          {{ $t('userManagement') }}
        </b-card-title>
        <b-card-sub-title>
          {{ $t('changesOnlyVisibleAfterNewLogin') }}
        </b-card-sub-title>
      </b-card-header>
      <b-card-body>
        <vue-good-table
          :rows="users"
          :columns="userColumns"
          :select-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: false,
            perPage:3
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <div
              v-if="props.column.field === 'access'"
            >
              <b-badge :variant="statusVariant(props.row.access)">
                {{ props.row.access }}
              </b-badge>
            </div>

            <div
              v-else-if="props.column.field === 'organizationIDs'"
            >
              <div
                v-for="organizationId in props.row.organizationIDs"
                :key="props.row.id + '-' + organizationId"
              >
                {{ getOrganizationById(organizationId) }}
              </div>
            </div>
            <div
              v-else-if="props.column.field === 'edit'"
            >

              <b-button-group style="width: 100%">
                <b-button
                  v-b-modal.user-modal
                  variant="primary"
                  class="btn-icon"
                  @click="selectedUser = props.row"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>
                <b-button
                  v-b-modal.confirm-delete-modal
                  variant="danger"
                  class="btn-icon"
                  @click="selectedUser = props.row"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </b-button-group>
            </div>
          </template>
        </vue-good-table>
        <b-button
          v-b-modal.user-modal
          class="mt-1"
          block
          variant="outline-primary"
          @click="selectedUser = undefined"
        >
          {{ $t('addUser') }}
          <feather-icon icon="UserPlusIcon" />
        </b-button>
      </b-card-body>
    </b-card>
    <b-modal
      v-if="selectedUser"
      id="confirm-delete-modal"
      :no-close-on-backdrop="true"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      ok-title="Löschen"
      :cancel-title="$t('cancel')"
      centered
      :title="$t('confirmDelete')"
      @ok="handleDelete"
    >
      {{ $t('areYouSureThatYou') }} <b>{{ selectedUser.name }}</b> {{ $t('wantToDelete') }}
    </b-modal>
    <user-modal
      :user="selectedUser"
      :organizations="organizations"
      @usersUpdated="getUsers"
    />
  </div>
</template>

<script>

import { VueGoodTable } from 'vue-good-table'
import {
  BBadge, BButton, BButtonGroup, BCard, BCardHeader, BCardTitle, BCardSubTitle, BCardBody,
} from 'bootstrap-vue'
import UserModal from '@/components/forms/UserModal.vue'

export default {
  name: 'UserManagement',
  components: {
    VueGoodTable, BBadge, BButton, UserModal, BCard, BButtonGroup, BCardHeader, BCardTitle, BCardSubTitle, BCardBody,
  },
  data() {
    return {
      users: [],
      organizations: [],
      selectedUser: undefined,
      userColumns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Zugriff',
          field: 'access',
        },
        {
          label: 'Organisationen',
          field: 'organizationIDs',
          sortable: false,
        },
        {
          label: 'Bearbeiten',
          field: 'edit',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Organization: 'light-primary',
        Suborganization: 'light-success',
        Superuser: 'light-danger',
        Cluster: 'light-warning',
        LocalGroup: 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    accessType() {
      const accessTypes = {
        /* eslint-disable key-spacing */
        Organization: 'Organisationsadmin',
        Suborganization: 'Länderadmin',
        Superuser: 'Superadmin',
        Cluster: 'Hausadmin',
        LocalGroup: 'Wohnungsadmin',
        /* eslint-enable key-spacing */
      }
      return access => accessTypes[access]
    },
  },
  async mounted() {
    await this.getOrganizations()
    await this.getUsers()
  },
  methods: {
    async getUsers() {
      const usersResponse = await this.$http.get('/users')
      this.users = usersResponse.data
    },
    async getOrganizations() {
      const organizationsResponse = await this.$http.get('/organizations')
      this.organizations = organizationsResponse.data
    },
    async handleDelete() {
      console.log('Deleting', this.selectedUser)
      await this.$store.dispatch('users/deleteUser', this.selectedUser)
      await this.getUsers()
    },
    getOrganizationById(id) {
      const filteredOrganization = this.organizations.filter(organization => organization.id === id)
      return filteredOrganization[0].name
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>
