<template>
  <b-modal
    id="user-modal"
    :no-close-on-backdrop="true"
    cancel-variant="outline-secondary"
    :ok-title="$t('save')"
    :cancel-title="$t('cancel')"
    centered
    :title="$t('user')"
    @hidden="resetModal"
    @show="loadData"
    @ok="handleSubmit"
  >
    <label for="name"> <h6>{{ $t('name') }}</h6> </label>
    <b-form-input
      id="name"
      v-model="selectedUser.name"
      class="mb-1"
      required
      :state="nameValid"
    />
    <label for="email"> <h6>{{ $t('emailAddress') }}</h6> </label>
    <b-form-input
      id="email"
      v-model="selectedUser.email"
      class="mb-1"
      required
      :state="emailValid"
    />
    <div v-if="!selectedUser.id">
      <label for="new-password"> <h6>{{ $t('password') }}</h6> </label>
      <b-form-input
        id="new-password"
        v-model="selectedUser.password"
        type="password"
        class="mb-1"
      />
      <label for="new-password-confirm"> <h6>{{ $t('confirmPassword') }}</h6> </label>
      <b-form-input
        id="new-password-confirm"
        v-model="selectedUser.passwordConfirm"
        type="password"
        class="mb-1"
      />
    </div>
    <label for="access"> <h6>{{ $t('access') }}</h6> </label>
    <b-form-select
      v-model="selectedUser.access"
      :options="accessLevels"
      class="mb-1"
      required
      :state="accessValid"
    />
    <br>
    <label for="organizationIDs"> <h6>{{ $t('organizations') }}</h6> </label>
    <b-form-checkbox-group
      id="checkbox-group-2"
      v-model="selectedUser.organizationIDs"
      name="flavour-2"
      :state="organisationsValid"
    >
      <div
        v-for="organization in organizations"
        :key="organization.id"
      >
        <b-form-checkbox :value="organization.id">
          {{ organization.name }}
        </b-form-checkbox>
        <br>
      </div>
    </b-form-checkbox-group>
  </b-modal>
</template>

<script>

import {
  BModal, BFormInput, BFormCheckbox, BFormCheckboxGroup, BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'UserModal',
  components: {
    BModal, BFormInput, BFormCheckbox, BFormCheckboxGroup, BFormSelect,
  },
  props: {
    user: {
      type: Object,
      default: () => ({
        name: '',
        email: '',
        access: '',
        organizationIDs: [],
      }),
    },
    organizations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedUser: {
        name: '',
        email: '',
        access: '',
        organizationIDs: [],
      },
      accessLevels: ['Superuser', 'Organization', 'Cluster'],
      submitPressed: false,
    }
  },
  computed: {
    nameValid() {
      if (this.submitPressed) {
        if (this.selectedUser.name !== '') {
          return true
        }
        if (this.selectedUser.name === '') {
          return false
        }
      }
      return undefined
    },
    emailValid() {
      if (this.submitPressed) {
        return (/^\S+@\S+\.\S+$/.test(this.selectedUser.email))
      }
      return undefined
    },
    accessValid() {
      if (this.submitPressed) {
        if (this.selectedUser.access !== '') {
          return true
        }
        if (this.selectedUser.access === '') {
          return false
        }
      }
      return undefined
    },
    organisationsValid() {
      if (this.submitPressed) {
        if (this.selectedUser.organizationIDs.length !== 0) {
          return true
        }
        if (this.selectedUser.organizationIDs.length === 0) {
          return false
        }
      }
      return undefined
    },
    allValid() {
      return this.nameValid && this.emailValid && this.accessValid && this.organisationsValid
    },
  },
  methods: {
    checkPassword() {
      if (this.selectedUser.password !== '' && (this.selectedUser.password === this.selectedUser.passwordConfirm)) {
        return true
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Passwörter sind leer oder stimmen nicht überein!',
          icon: 'EditIcon',
          variant: 'danger',
        },
      })
      return false
    },
    resetModal() {
      this.selectedUser = {
        name: '',
        email: '',
        access: '',
        organizationIDs: [],
        password: '',
        passwordConfirm: '',
        id: undefined,
      }
    },
    loadData() {
      this.submitPressed = false
      if (this.user.id) {
        this.selectedUser.id = this.user.id
      }
      this.selectedUser.password = ''
      this.selectedUser.passwordConfirm = ''
      this.selectedUser.name = this.user.name
      this.selectedUser.email = this.user.email
      this.selectedUser.access = this.user.access
      this.selectedUser.organizationIDs = this.user.organizationIDs
    },
    async handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.submitPressed = true
      if (this.selectedUser.id && this.allValid) {
        try {
          await this.$store.dispatch('users/updateUser', this.selectedUser)
          this.$emit('usersUpdated')
          this.makeToast(`${this.selectedUser.name} erfolgreich bearbeitet!`, 'CheckIcon', 'success', '')
          this.$nextTick(() => {
            this.$bvModal.hide('user-modal')
          })
        } catch (e) {
          console.log(e)
          this.makeToast('Bitte Daten überprüfen!', 'XIcon', 'danger', '')
        }
      } else if (this.allValid && this.checkPassword()) {
        try {
          await this.$store.dispatch('users/addUser', this.selectedUser)
          this.$emit('usersUpdated')
          this.makeToast(`${this.selectedUser.name} erfolgreich erstellt!`, 'CheckIcon', 'success', '')
          this.$nextTick(() => {
            this.$bvModal.hide('user-modal')
          })
        } catch (e) {
          this.makeToast('Bitte Daten überprüfen!', 'XIcon', 'danger', '')
        }
      } else if (!this.allValid) {
        this.makeToast('Daten nicht vollständig!', 'XIcon', 'danger', '')
      }
    },
    makeToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
